import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

/** @type {{thumbnail: React.CSSProperties}} */
const style = {
    thumbnail: {
        float: 'left',
        maxHeight: 190
    }
} as any;

export class Projects extends Component {
    state = { repositories: [] }
    componentWillMount() {
        fetch('https://api.github.com/users/cime/repos').then((response) => response.json()).then((repositories) => {
            this.setState((state) => ({...state, repositories: repositories.filter((x: any) => !x.fork)}))
        });
    }

    renderRepositories() {
        return <div className="list-group">
            {
                this.state.repositories.map((repo: any) => <a href={repo.html_url} target="_blank" title={repo.name + ' - ' + repo.description} className="list-group-item list-group-item-action" key={repo.id}>
                    <h5 className="mb-1">{repo.name}</h5>
                    <p className="mb-1">{repo.description}</p>
                    <small>Language: <strong>{repo.language}</strong></small>
                </a>)
            }
        </div>
    }

    render() {
        return <>
            <Row className="mt-5">
                <Col>
                    <h2><i className="fab fa-github"></i> Github</h2>

                    { this.state.repositories ? this.renderRepositories() : <span>Loading...</span> }
                </Col>
            </Row>
        </>;
    }
}
